<script setup>
import { computed } from 'vue'
import Icon from '@components/Icon.vue'

const props = defineProps({
  total: {
    type: [Number, String],
    required: true,
  },
  rating: {
    type: [Number, String],
    default: 0,
  },
  showTotal: {
    type: Boolean,
    default: true,
  },
  showRating: {
    type: Boolean,
    default: true,
  },
  truncate: {
    type: Boolean,
    default: true,
  },
})

const width = computed(() => {
  return props.total > 0 ? ((props.rating / 5) * 100).toFixed(2) : 0;
})
</script>

<template>
  <div class="rating" v-if="showRating && total > 0">
    <div class="rating__stars lazy-bg">
      <div class="rating__full" :style="`width: ${ width }%;`">
        <img src="/images/5-stars.svg" width="82" height="17" alt="5 stars" loading="lazy" />
      </div>
    </div>
    <span class="rating__text rating__text--total" v-if="showTotal">
      <template v-if="truncate">{{ total }}</template>
      <template v-else>{{ total }} Reviews</template>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.rating {
  display: flex;
  align-items: center;

  .rating__text {
    padding-left: 4px;
    line-height: 1;

    @media screen and (min-width: 768px) {
      padding-top: 4px;
    }
  }

  .rating__stars {
    background-image: url(/images/5-stars-empty.svg);
    background-repeat: no-repeat;
    background-size: cover;

    img {
      width: auto;
      height: 16px;
      display: block;

      @media screen and (min-width: 768px) {
        height: 18px;
      }
    }

    .rating__full {
      overflow: hidden;
    }
  }
}
</style>
