<script setup>
import {computed} from 'vue'

const props = defineProps(['listing'])

const effects = computed(() => {
  return ['Indica', 'Sativa', 'Hybrid'].filter(e => props.listing?.tags?.includes(e)).map(e => e.toLowerCase())
})
</script>

<template>
  <div class="listing__tags">
    <div v-if="listing.potencies?.length && !listing.title.toLowerCase().includes('disposable') && !listing.title.toLowerCase().includes('thca')" class="listing__potencies">
      <span v-for="potency in listing.potencies" :key="potency" class="listing__potency">
        {{ potency }}/Pc
      </span>
    </div>
    <div v-if="effects.length" class="listing__effects">
      <span v-for="effect in effects" :key="effect" :class="`listing__effect listing__effect--${effect}`">
        <img :src="`/images/${effect}.svg`" :alt="effect" width="12" height="12" loading="lazy" />
        {{ effect }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listing__effects {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  .listing__effect {
    &.listing__effect--indica {
      color: #12303F;
      background: rgba(3, 64, 94, 0.08);
    }

    &.listing__effect--sativa {
      color: #CF6D47;
      background: rgba(207, 109, 71, 0.08);
    }

    &.listing__effect--hybrid {
      color: #3E938F;
      background: rgba(62, 147, 143, 0.10);
    }

    display: inline-flex;
    align-items: center;
    padding: 2px 4px;
    font-family: Outfit, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
    border-radius: 4px;
    gap: 2px;

    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.listing__potencies {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 6px;

  .listing__potency {
    display: inline-block;
    color: #183D4F;
    font-family: Outfit, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    padding: 2px 8px;
    background: rgba(24, 61, 79, 0.10);
    border-radius: 4px;

    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
